import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  tier2NavigationModeOptions,
  ASSISTED_WORKS,
  MIN_LABELERS_NUMBER,
  MAX_LABELERS_NUMBER,
} from '../../SharedLogic';
import AutocompleteOrCreateNew from '../../../../../components/AutocompleteOrCreateNew';
import { RichTextEditor } from '../../../../../components';
import styles from './CampaignSettings.module.css';
import { LabeledSwitch } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCampaignGuidance,
  selectConfig,
  setCampaignsPreviousData,
} from '../../../../../redux/tasks/tasksSlice';

const EditCampaignSettings = ({
  campaignsList,
  campaignName,
  setCampaignName,
  campaignOwner,
  setCampaignOwner,
  setPreSelectedCampaignBatches,
  campaignGroup,
  setCampaignGroup,
  campaignsGroups,
  isHidden,
  setIsHidden,
  setSelectedBatches,
  numberOfTier1Labelers,
  setNumberOfTier1Labelers,
  tier2NavigationMode,
  setTier2NavigationMode,
  assistedWork,
  setAssistedWork,
  aiAsAdditionalTier1,
  setAiAsAdditionalTier1,
  disableAutoCorrection,
  setDisableAutoCorrection,
  campaignGuidance,
  handleChangeCampaignGuidance,
  setSelectedFindings,
  setPreSelectedUsersAndRoles,
  setInactivatedUserAndRoles,
  setSelectedUsersAndRoles,
  setInactivatedFindings,
  setPreSelectedFindings,
  selectedUsersAndRoles,
  updatedNumberOfTier1Labellers,
  setUpdatedNumberOfTier1Labellers,
  fetchData,
}) => {
  const config = useSelector(selectConfig);
  const campaignGuidances = useSelector(selectCampaignGuidance);
  useEffect(() => {
    if (config && campaignName) {
      const selectedCampaignConfig = config.campaigns.find(
        (campaign) => campaign.campaign_name === campaignName
      );

      if (selectedCampaignConfig) {
        if (!updatedNumberOfTier1Labellers) {
          setNumberOfTier1Labelers(
            selectedCampaignConfig?.params?.number_of_tier1_labelers
          );
        }
        setTier2NavigationMode(
          selectedCampaignConfig.params.tier_2_navigation_mode
        );
        setDisableAutoCorrection(
          selectedCampaignConfig.auto_correction_disable
        );
        setIsHidden(selectedCampaignConfig.hidden);
        setAssistedWork(selectedCampaignConfig.assistedWork);
      }
    }
  }, [
    config,
    campaignName,
    setNumberOfTier1Labelers,
    setTier2NavigationMode,
    setDisableAutoCorrection,
    setIsHidden,
    setAssistedWork,
    updatedNumberOfTier1Labellers,
  ]);
  const dispatch = useDispatch();

  const handleCampaignChange = async (event, campaignData) => {
    setCampaignName(campaignData?.name);
    setCampaignGroup(campaignData?.group);
    if (updatedNumberOfTier1Labellers) setUpdatedNumberOfTier1Labellers(0);
    if (campaignData?.id) {
      const selectedCampaignConfig = config.campaigns.find(
        (campaign) => campaign['campaign_id'] === campaignData?.id
      );
      setCampaignOwner(selectedCampaignConfig['campaign_owner']);
      const guidance = campaignGuidances[campaignData?.id];
      handleChangeCampaignGuidance(guidance);
      const campaignSelectedBatches = config.campaigns.find(
        (campaign) => campaign['campaign_id'] === campaignData?.id
      ).params.batches;
      setSelectedBatches(campaignSelectedBatches);
      setPreSelectedCampaignBatches(campaignSelectedBatches);

      const preSelectedUsersAndRoles = config?.campaigns.find(
        (campaign) => campaign?.campaign_id === campaignData?.id
      ).users;
      const inactivatedUsersAndRoles =
        config?.campaigns.find(
          (campaign) => campaign?.campaign_id === campaignData?.id
        ).inactivatedUserAndRoles || [];
      setPreSelectedUsersAndRoles(preSelectedUsersAndRoles);
      setInactivatedUserAndRoles(inactivatedUsersAndRoles);
      setSelectedUsersAndRoles(() =>
        selectedUsersAndRoles.map((user) => {
          const preSelectedRoles = preSelectedUsersAndRoles[user.UserId] || [];
          const updatedRoles = updateRolesMap(user, preSelectedRoles);
          return {
            ...user,
            roles: updatedRoles,
          };
        })
      );

      const preSelectedFindings = config?.campaigns.find(
        (campaign) => campaign?.campaign_id === campaignData?.id
      ).findings;
      const campaignInactivatedFindings =
        config?.campaigns.find(
          (campaign) => campaign?.campaign_id === campaignData?.id
        ).inactivatedFindings || [];

      setSelectedFindings(preSelectedFindings);
      setPreSelectedFindings(preSelectedFindings);
      setInactivatedFindings([...campaignInactivatedFindings]);

      await fetchData(true, campaignSelectedBatches);

      const prevCampaignDataObj = {
        campaignId: campaignsList?.find((x) => x.name === campaignData?.name)
          ?.id,
        campaignName: campaignData?.name,
        campaignOwner: selectedCampaignConfig['campaign_owner'],
        campaignGroup: campaignData?.group,
        usersAndRoles: preSelectedUsersAndRoles,
        batches: campaignSelectedBatches,
        hidden: isHidden,
        numberOfTier1Labelers: config?.campaigns.find(
          (campaign) => campaign?.campaign_id === campaignData?.id
        )?.params.number_of_tier1_labelers,
        tier2NavigationMode,
        assistedWork,
        aiAsAdditionalTier1,
        disableAutoCorrection: selectedCampaignConfig.auto_correction_disable,
        findings: preSelectedFindings,
        campaignGuidance: campaignGuidances[campaignData?.id],
      };
      dispatch(setCampaignsPreviousData(prevCampaignDataObj));
    }
  };

  const updateRolesMap = (user, preSelectedRoles) => {
    return user.roles.map((role) => ({
      ...role,
      checked: preSelectedRoles.includes(role.label),
    }));
  };

  return (
    <>
      <Autocomplete
        options={campaignsList}
        getOptionLabel={(option) => option.name}
        value={
          campaignsList.find((campaign) => campaign.name === campaignName) ||
          null
        }
        onChange={handleCampaignChange}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} label="Campaign" required variant="outlined" />
        )}
        selectOnFocus
        handleHomeEndKeys
      />
      {campaignName && (
        <>
          <TextField
            required
            label="Campaign Owner"
            value={campaignOwner}
            onChange={(e) => setCampaignOwner(e.target.value)}
          />
          <AutocompleteOrCreateNew
            selectedValue={campaignGroup}
            setSelectedValue={setCampaignGroup}
            values={campaignsGroups}
            fieldlabel="Campaign Group"
          />
          <TextField
            required
            label="Number of Tier1 Labelers"
            value={updatedNumberOfTier1Labellers || numberOfTier1Labelers}
            onChange={(e) => {
              setUpdatedNumberOfTier1Labellers(e.target.value);

              return setNumberOfTier1Labelers(e.target.value);
            }}
            type="number"
            inputProps={{
              min: MIN_LABELERS_NUMBER,
              max: MAX_LABELERS_NUMBER,
              step: 1,
            }}
            onKeyDown={(e) => e.preventDefault()}
          />
          <FormControl>
            <InputLabel id="select-tier2-navigation-mode-label">
              Tier2 Navigation Mode
            </InputLabel>
            <Select
              labelId="select-tier2-navigation-mode-label"
              required
              label={'Tier2 Navigation Mode'}
              value={tier2NavigationMode}
              onChange={(e) => setTier2NavigationMode(e.target.value)}
            >
              {tier2NavigationModeOptions?.map((option) => (
                <MenuItem required key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="select-assisted-work-label">
              Assisted Work
            </InputLabel>
            <Select
              labelId="select-assisted-work-label"
              required
              label={'Assisted Work'}
              value={assistedWork}
              onChange={(e) => setAssistedWork(e.target.value)}
            >
              {ASSISTED_WORKS?.map((option) => (
                <MenuItem required key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LabeledSwitch
            checked={disableAutoCorrection}
            setChecked={setDisableAutoCorrection}
            label={'Disable Auto Correction'}
          />
          <LabeledSwitch
            checked={aiAsAdditionalTier1}
            setChecked={setAiAsAdditionalTier1}
            label="AI as additional Tier1"
          />
          <LabeledSwitch
            checked={isHidden}
            setChecked={setIsHidden}
            label={'Hidden'}
          />
          <FormControl>
            <InputLabel shrink>Campaign Guidance</InputLabel>
            <RichTextEditor
              className={styles.richTextEditor}
              value={campaignGuidance}
              onChange={handleChangeCampaignGuidance}
              placeHolder="Campaign Guidance..."
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export default EditCampaignSettings;
