import React, { useState, useEffect, useRef, useCallback } from 'react';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { AutoSizer } from 'react-virtualized';
import { useHistory } from 'react-router-dom';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Button, Table } from '../../components';
import { TaskTypes } from '../../shared-logic/enums';
import Header from './Header';
import styles from './TasksList.module.css';
import {
  labelerColumns,
  tasksColumns,
  campaignsColumns,
  deletedTasksColumns,
} from './columns';
import { useSelector, useDispatch } from 'react-redux';
import { HourglassProgress } from '../../components';
import classNames from 'classnames';
import {
  setLabeler,
  setCampaign,
  setFavoriteCampaign,
  selectFavoriteCampaign,
  selectLabeler,
  selectCampaign,
  selectConfig,
  setDeletedTasks,
  selectDeletedTasks,
  selectRole,
  setRole,
  setMinNumberOfLabelers,
  selectMinNumberOfLabelers,
  setPotentiallyDeletedTasks,
  selectPotentiallyDeletedTasks,
} from '../../redux/tasks/tasksSlice';
import { useGetTaskListLogic } from '../../hooks';
import { getUserName } from '../TasksList/TasksList.logic';
import Roles from './rolesEnum';
import {
  isWatcher,
  isTier2Reviewer,
  isLabeler,
  isSupervisor,
  isTier2Labeler,
} from './rolesUtil';
import {
  setCompleted,
  setInTier1,
  setReason,
} from '../../redux/taskState/taskDetailsSlice';
import userActionLogs from '../../shared-logic/userActionLogs';
import TasksFilterControls from './TasksFilterControls/TasksFilterControls';
import CampaignGuidance from './CampaignGuidance';
import { useSaveStateSessionStorage } from '../LabelingToolPage/customHooks';
import { isEqual } from 'lodash';
import { ipData } from '../../shared-logic/fetchApi';
import SearchSubNickName from './SearchSubNickName/SearchSubNickName';
import { setShowMissPoints } from '../../redux/marks/currentMarkingSlice';
import {
  getUserCampaignsIncludeHidden,
  getCampaignName,
  getCampaignGroup,
} from '../../config/configUtil';
import BugReportByUser from '../bugReport/BugReportByUser';
import CheckBox from '../../components/CheckBox';
const pjson = require('../../../package.json');
const DELETED_TASKS = 'Deleted Tasks';
const POTENTIALLY_DELETED_TASKS = 'Potentially Deleted Tasks';

const TasksList = ({ error }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tasksListLogic = useGetTaskListLogic();
  const userName = getUserName();
  const selectedCampaign = useSelector(selectCampaign);
  const selectedLabeler = useSelector(selectLabeler);
  const selectedFavoriteCampaign = useSelector(selectFavoriteCampaign);

  const role = useSelector(selectRole);
  const deletedTasks = useSelector(selectDeletedTasks, isEqual);
  const potentiallyDeletedTasks = useSelector(
    selectPotentiallyDeletedTasks,
    isEqual
  );
  const minNumberOfLabelers = useSelector(selectMinNumberOfLabelers);

  const [selectedLabelerRow, setSelectedLabelerRow] = useState(selectedLabeler);
  const [selectedTaskRow, setSelectedTaskRow] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [campaignsToRolesMap, setCampaignsToRolesMap] = useState();
  const [labelerData, setLabelerData] = useState();
  const [tasks, setTasks] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showInProgress, setShowInProgress] = useState(false);
  const [goToLabelingDisabled, setGoToLabelingDisabled] = useState(true);
  const [selectedTask, setSelectedTask] = useState({});
  const [deletedTaskRow, setDeletedTaskRow] = useState(-1);
  const deletedClassName = classNames([styles.marginTop, styles.flex]);
  const [searchText, setSearchText] = useState('');
  const [searchSubNickName, setSearchSubNickName] = useState('');
  const [specificTask, setSpecificTask] = useState('');
  const [openBugWindow, setOpenBugWindow] = useState(false);

  const [querySubNickName, setQuerySubNickName] = useState('');
  const isFirstTime = useRef(true);

  const config = useSelector(selectConfig);
  const saveStateSessionStorage = useSaveStateSessionStorage();

  //Save data after login in the middle of the post data
  useEffect(() => {
    saveStateSessionStorage();
  }, [saveStateSessionStorage]);

  const getInitialLabelers = useCallback(
    (config, campaignsToRolesMap) => {
      const assignees = tasksListLogic.getAssignees(
        config,
        selectedCampaign
          ? selectedCampaign
          : [...campaignsToRolesMap.keys()][0],
        userName,
        role
      );
      return assignees.map((x) => {
        return { id: x.id, labeler: x.name, tier: x.tier };
      });
    },
    [role, selectedCampaign, tasksListLogic, userName]
  );

  const fetchConfigAndSetVars = useCallback(async () => {
    if (!config) return;
    const userCampaignsRoles = tasksListLogic.getUserCampaignsAndRoles(
      config,
      userName
    );
    userCampaignsRoles.forEach((value, key, map) => {
      if (value.length === 0) {
        map.delete(key);
      }
    });
    setCampaignsToRolesMap(userCampaignsRoles);

    setLabelerData(getInitialLabelers(config, userCampaignsRoles));
    !role &&
      dispatch(
        setRole(
          selectedCampaign
            ? userCampaignsRoles.get(selectedCampaign)[0]
            : userCampaignsRoles.values().next().value[0]
        )
      );
  }, [
    config,
    dispatch,
    getInitialLabelers,
    role,
    selectedCampaign,
    tasksListLogic,
    userName,
  ]);

  const getSelectedCampaign = useCallback(
    () =>
      selectedCampaign
        ? selectedCampaign
        : campaignsToRolesMap.keys().next().value,
    [selectedCampaign, campaignsToRolesMap]
  );

  const retrieveTasks = useCallback(async () => {
    try {
      setLoading(true);
      if (!selectedLabeler) return;
      let newTasks = await tasksListLogic.getTasks(
        config,
        getSelectedCampaign(),
        selectedLabeler,
        isSupervisor(role) || isWatcher(role),
        isTier2Reviewer(role),
        role,
        querySubNickName
      );
      setTasks(newTasks);
    } catch (error) {
      const ip = await ipData();
      ip &&
        NotificationManager.error(
          `Could not fetch tasks, please verify you are properly connected to VPN, IP address: ${ip}`,
          'Warning'
        );
    } finally {
      setLoading(false);
    }
  }, [
    selectedLabeler,
    tasksListLogic,
    config,
    getSelectedCampaign,
    role,
    querySubNickName,
  ]);

  const handleLabelerSelection = useCallback(
    async (labeler) => {
      userActionLogs.addActionLog(`labeler ${labeler.id} was selected`);
      setTasks([]);
      setGoToLabelingDisabled(true);
      setSelectedTaskRow(-1);
      setSelectedLabelerRow(labeler.id);
      if (isEqual(labeler, selectedLabeler)) {
        await retrieveTasks();
        return;
      }
      dispatch(setLabeler(labeler));
    },
    [dispatch, selectedLabeler, retrieveTasks]
  );

  useEffect(() => {
    fetchConfigAndSetVars();
  }, [config, fetchConfigAndSetVars]);

  useEffect(() => {
    if (
      selectedLabeler &&
      selectedLabeler !== -1 &&
      campaignsToRolesMap &&
      (isSupervisor(role) || isWatcher(role))
    ) {
      handleLabelerSelection(selectedLabeler);
    }
  }, [handleLabelerSelection, role, selectedLabeler, campaignsToRolesMap]);

  useEffect(() => {
    if (error && campaignsToRolesMap) {
      NotificationManager.error(error, 'Warning');
    }
  }, [error, campaignsToRolesMap]);

  const fetchAndMapDeletedTasks = useCallback(async () => {
    const res = await tasksListLogic.getDeletedTasks(config, selectedCampaign);
    if (res) {
      const { deletedTasks, potentiallyDeletedTasks } = res;
      if (deletedTasks && potentiallyDeletedTasks) {
        [...deletedTasks, ...potentiallyDeletedTasks].forEach((t) => {
          t.id = `${t.TaskId}${t.Level ? t.Level[0] : ''}`;
          t.Type = t.Type || TaskTypes._5D;
          t.Labeler = t.Assignee?.split('@')[0];
        });
      }
      return { deletedTasks, potentiallyDeletedTasks };
    }
  }, [config, selectedCampaign, tasksListLogic]);

  const fetchData = useCallback(async () => {
    if (
      isSupervisor(role) &&
      ((isFirstTime.current && deletedTasks && deletedTasks.length === 0) ||
        !isFirstTime.current)
    ) {
      isFirstTime.current = false;
      const { deletedTasks, potentiallyDeletedTasks } =
        await fetchAndMapDeletedTasks();
      dispatch(setDeletedTasks(deletedTasks));
      dispatch(setPotentiallyDeletedTasks(potentiallyDeletedTasks));
    }
  }, [deletedTasks, dispatch, fetchAndMapDeletedTasks, role]);

  useEffect(() => {
    !selectedFavoriteCampaign &&
      campaignsToRolesMap &&
      tasksListLogic
        .getFavoriteCampaign(userName, campaignsToRolesMap)
        .then((fv) => dispatch(setFavoriteCampaign(fv)));
    !selectedCampaign &&
      campaignsToRolesMap &&
      selectedFavoriteCampaign &&
      dispatch(setCampaign(selectedFavoriteCampaign)) &&
      dispatch(setRole(campaignsToRolesMap.get(selectedFavoriteCampaign)[0]));

    config &&
      selectedCampaign &&
      dispatch(
        setMinNumberOfLabelers(
          tasksListLogic.getNumberOfLabelersPerTask(config, selectedCampaign)
        )
      );
    !role &&
      campaignsToRolesMap &&
      dispatch(setRole(campaignsToRolesMap.get(selectedCampaign)[0]));

    fetchData();
  }, [
    selectedFavoriteCampaign,
    selectedCampaign,
    role,
    campaignsToRolesMap,
    config,
    userName,
    dispatch,
    fetchData,
    tasksListLogic,
  ]);

  useEffect(() => {
    setShowCompleted(false);
    setShowInProgress(false);
    if (isTier2Labeler(role, selectedLabeler)) {
      setShowInProgress(true);
    }
    setSearchText('');
  }, [retrieveTasks, role, selectedLabeler]);
  const handleCampaignSelection = (selection) => {
    userActionLogs.addActionLog(`campaign ${selection} was selected`);
    dispatch(setCampaign(selection));
    dispatch(
      setMinNumberOfLabelers(
        tasksListLogic.getNumberOfLabelersPerTask(config, selection)
      )
    );
    dispatch(setLabeler(-1));
    setSelectedLabelerRow(-1);
    setQuerySubNickName('');
    setSearchSubNickName('');
    if (selection !== selectedCampaign) {
      dispatch(setRole(campaignsToRolesMap.get(selection)[0]));
    }
  };

  useEffect(() => {
    if (selectedCampaign) {
      if (campaignsToRolesMap) {
        if (
          role === Roles.TIER1 ||
          role === Roles.TIER2 ||
          role === Roles.TIER3
        ) {
          // if labeler only show tasks
          const assignees = tasksListLogic.getAssignees(
            config,
            selectedCampaign,
            userName,
            role
          );
          handleLabelerSelection({ id: userName, tier: assignees[0].tier });
        } else {
          setTasks([]);
          setGoToLabelingDisabled(true);
          const assignees = tasksListLogic.getAssignees(
            config,
            selectedCampaign,
            userName,
            role
          );
          if (!querySubNickName) {
            setLabelerData(
              assignees.map((x) => {
                return { id: x.id, labeler: x.name, tier: x.tier };
              })
            );
          }
        }
      }
    }
  }, [
    role,
    campaignsToRolesMap,
    selectedCampaign,
    config,
    userName,
    querySubNickName,
    handleLabelerSelection,
    tasksListLogic,
  ]);

  const handleTaskSelection = (task) => {
    userActionLogs.addActionLog(`task ${task.id} was selected`);
    dispatch(setCompleted(task.Completed));
    dispatch(setInTier1(task.InTier1));
    dispatch(setReason(task.Reason));
    dispatch(setShowMissPoints(task.ShowMissPointsInProgress));

    setSelectedTask(task);
    setDeletedTaskRow(-1);
    setSelectedTaskRow(task.id);
    setGoToLabelingDisabled(false);
  };

  const handleDeletedTaskSelection = (task) => {
    setSelectedTaskRow(-1);
    setGoToLabelingDisabled(true);
    setDeletedTaskRow(task.id);
  };

  const retrieveAnotherTask = async () => {
    userActionLogs.addActionLog('get next task called');
    try {
      const task = await tasksListLogic.getTask(
        config,
        getSelectedCampaign(),
        selectedLabeler,
        minNumberOfLabelers,
        role,
        specificTask
      );
      !tasks.find((t) => t.id === task.id) && setTasks([...tasks, task]);
    } catch (error) {
      const noMoreTasks = 'No relevant tasks exist';

      const specificTask = 'No relevant tasks exist with specificTask';
      const ip = await ipData();
      let msg =
        error === noMoreTasks || error === specificTask
          ? error
          : 'Could not fetch next task, please verify you are properly connected to VPN, IP:' +
            ip;
      NotificationManager.error(msg, 'Warning');
    }
    setSpecificTask('');
  };

  const handleSearchSubNickName = async () => {
    setQuerySubNickName(searchSubNickName);
    if (searchSubNickName) {
      const assignees = await tasksListLogic.getLabelersByNickName(
        config,
        getSelectedCampaign(),
        searchSubNickName
      );
      let counter = 0;
      const labelers = assignees.map((labeler) => {
        return { id: counter++, labeler: labeler.labeler, tier: labeler.tier };
      });
      setLabelerData(labelers.filter((value) => value.id !== null));
    }
  };

  const goToTask = () => {
    history.push({
      pathname: '/task',
      search: `?mode=labeling&taskId=${selectedTask.TaskId}&assignee=${
        selectedTask.Assignee
      }&path=${selectedTask.PathToData}${
        role === 'watcher' || role === 'tier2-reviewer' ? '&watcher=true' : ''
      }&batch=${selectedTask.Batch}&tier=${selectedTask.Tier}&type=${
        selectedTask.Type
      }&level=${selectedTask.Level}${
        selectedTask.PotentiallyDiscarded ? '&potentiallyDiscarded=true' : ''
      }`,
    });
  };

  const unTakeTask = async () => {
    try {
      await tasksListLogic.unTakeTask(
        selectedTask.Level,
        selectedTask.Tier,
        selectedTask.Assignee,
        selectedTask.PathToData,
        selectedTask.InactivatedTask
      );
      NotificationManager.success('Undo task', 'Success!');
      handleLabelerSelection(selectedLabeler);
    } catch (err) {
      NotificationManager.error('an error occured when return task', 'Warning');
    }
  };

  const getCampaigns = () => {
    const campaigns = [...campaignsToRolesMap.keys()];
    return campaigns.map((x) => {
      return {
        id: x,
        name: getCampaignName(config, x),
        group: getCampaignGroup(config, x),
      };
    });
  };

  const filterData = () => {
    const isCompleted = (t) => t.Completed;
    // use this once we figure out an optimal solution
    //t.Tier === 1 ? t.Completed && !t.MovedToTier2 : t.Completed;
    const isInProgress = (t) => t.PercentCompleted < 100;
    //isLabeler(role) ? t.PercentCompleted < 100 : !t.Completed;
    //
    //filter list of tasks according to search field (if length of input larger than 2)
    const filterTasks =
      searchText.length > 2
        ? tasks.filter((t) =>
            t.id.toLowerCase().includes(searchText.toLowerCase())
          )
        : tasks;

    return showCompleted
      ? filterTasks.filter((t) => isCompleted(t))
      : showInProgress
      ? filterTasks.filter((t) => isInProgress(t))
      : filterTasks;
  };

  const selectedCampaignRole = () => {
    return campaignsToRolesMap.get(getSelectedCampaign());
  };

  const hourglassStyling = () => {
    const isLabeler = selectedCampaignRole() === Roles.LABELER;
    const className = classNames({
      [styles.center]: isLabeler,
      [styles.hourglass]: !isLabeler,
      [styles.hidden]: !loading,
    });
    return className;
  };

  const renderDeletedTasksTable = (
    tasks,
    label,
    selectedRow,
    handleSelection,
    className = ''
  ) => {
    return (
      tasks &&
      tasks.length > 0 && (
        <div className={className}>
          <div className={styles.paragraph}>{label}</div>
          <Table
            width={600}
            height={250}
            headerHeight={30}
            data={tasks}
            columns={deletedTasksColumns}
            selectedRowId={selectedRow}
            onRowSelect={(task) => handleSelection(task)}
            dynamicCellHeight={true}
          />
        </div>
      )
    );
  };

  const [splitKey, setSplitKey] = useState(0);
  const version = pjson.version;
  const goToBtnStyle = classNames([styles.goToBtn, styles.btn]);
  const unTakeBtnStyle = classNames([styles.unTakeBtn, styles.btn]);
  const reportABugBtn = classNames([styles.reportABugBtn, styles.btn]);

  const nextTaskStyle = classNames([styles.nextTask, styles.btn]);

  const onRoleChange = async (roleItem) => {
    setSplitKey((prevKey) => prevKey + 1);

    dispatch(setRole(roleItem));
    dispatch(setLabeler(-1));
    setSelectedLabelerRow(-1);
    if (roleItem && roleItem !== Roles.SUPERVISOR) {
      dispatch(setDeletedTasks(null));
      dispatch(setPotentiallyDeletedTasks(null));
    } else {
      const { deletedTasks, potentiallyDeletedTasks } =
        await fetchAndMapDeletedTasks();
      dispatch(setDeletedTasks(deletedTasks));
      dispatch(setPotentiallyDeletedTasks(potentiallyDeletedTasks));
    }
  };

  let campaignList = campaignsToRolesMap && getCampaigns();
  const heightCampaignTable =
    campaignList && Math.min(campaignList.length * 60, 300);
  const tasksColumnsWithSumTasks = [...tasksColumns];
  tasksColumnsWithSumTasks[0] = {
    ...tasksColumnsWithSumTasks[0],
    label: `Task(${filterData().length})`,
  };
  const handleCheckboxChange = async (campaignId) => {
    dispatch(setFavoriteCampaign(campaignId));
    await tasksListLogic.changeFavoriteCampaign(userName, campaignId);
  };

  const fetchHiddenCampaign = (event) => {
    if (event.target.checked) {
      const hiddenCampaigns = getUserCampaignsIncludeHidden(config, userName);
      setCampaignsToRolesMap(hiddenCampaigns);
    } else {
      const activeCampaigns = tasksListLogic.getUserCampaignsAndRoles(
        config,
        userName
      );
      setCampaignsToRolesMap(activeCampaigns);
    }
  };

  return config && campaignsToRolesMap ? (
    <>
      <div className={styles.homePage}>
        <div className={hourglassStyling()}>
          <HourglassProgress width={'35'} />
        </div>
        <Header
          listRoles={campaignsToRolesMap.get(selectedCampaign)}
          name={userName}
          role={role}
          isTier2={isTier2Labeler(role, selectedLabeler)}
          onRoleChange={onRoleChange}
          campaignsToRolesMap={campaignsToRolesMap}
          campaignsToRolesMapIncludeHidden={getUserCampaignsIncludeHidden(
            config,
            userName
          )}
        />
        <div className={styles.container}>
          <SplitterLayout
            key={splitKey}
            percentage={true}
            primaryMinSize={30}
            secondaryMinSize={!isLabeler(role) ? 65 : 50}
            secondaryInitialSize={70}
          >
            <div className={styles.leftSection}>
              <div style={{ 'min-height': 'calc(100% - 42px)' }}>
                <div className={styles.inlineContainer}>
                  <p className={styles.sidebarParagraph}>Select a Campaign</p>
                  {isSupervisor(role) && (
                    <CheckBox
                      label={'Hidden'}
                      onChange={fetchHiddenCampaign}
                      classNameProp={styles.sidebarParagraph}
                    ></CheckBox>
                  )}
                </div>

                <div
                  className={styles.campaignsTable}
                  style={{ height: heightCampaignTable }}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <Table
                        selectedRowId={getSelectedCampaign()}
                        width={width}
                        height={height}
                        data={campaignList}
                        columns={campaignsColumns}
                        headerHeight={30}
                        onRowSelect={(selection) =>
                          handleCampaignSelection(selection.id)
                        }
                        tableName={'campaigns'}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedFavorite={selectedFavoriteCampaign}
                      />
                    )}
                  </AutoSizer>
                </div>

                <CampaignGuidance />
              </div>

              <div className={styles.version}>V{version}</div>
            </div>
            <div className={styles.rightSection}>
              <div className={styles.labeler}>
                <div className={isLabeler(role) ? styles.hidden : ''}>
                  <SearchSubNickName
                    handleSearchSubNickName={handleSearchSubNickName}
                    setSearchSubNickName={setSearchSubNickName}
                    searchSubNickName={searchSubNickName}
                  />
                  <Table
                    selectedRowId={selectedLabelerRow}
                    width={400}
                    height={500}
                    headerHeight={30}
                    data={labelerData}
                    columns={labelerColumns}
                    onRowSelect={(labeler) => dispatch(setLabeler(labeler))}
                    tableName={'labelers'}
                  />
                </div>
                {true && (
                  <div className={deletedClassName}>
                    {renderDeletedTasksTable(
                      deletedTasks,
                      DELETED_TASKS,
                      deletedTaskRow,
                      handleDeletedTaskSelection,
                      styles.deletedTable
                    )}
                    {renderDeletedTasksTable(
                      potentiallyDeletedTasks,
                      POTENTIALLY_DELETED_TASKS,
                      selectedTaskRow,
                      handleTaskSelection
                    )}
                  </div>
                )}

                <div className={styles.flex}>
                  <div>
                    <TasksFilterControls
                      tasks={tasks}
                      role={role}
                      showCompleted={showCompleted}
                      setShowCompleted={setShowCompleted}
                      showInProgress={showInProgress}
                      setShowInProgress={setShowInProgress}
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />

                    <Table
                      width={400}
                      height={500}
                      selectedRowId={selectedTaskRow}
                      headerHeight={30}
                      data={filterData()}
                      columns={tasksColumnsWithSumTasks}
                      onRowSelect={(task) => handleTaskSelection(task)}
                      tableName={'tasks'}
                    />
                  </div>
                  {isLabeler(role) && (
                    <div className={styles.pullTask}>
                      <button
                        className={nextTaskStyle}
                        onClick={retrieveAnotherTask}
                      >
                        Next Task
                      </button>
                      <input
                        placeholder={'specific task'}
                        type="text"
                        value={specificTask}
                        onChange={(e) => setSpecificTask(e.target.value)}
                        className={styles.specificTaskText}
                      ></input>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.buttonSection}>
                {!isLabeler(role) && (
                  <Button
                    className={unTakeBtnStyle}
                    disabled={goToLabelingDisabled || selectedTask.Completed}
                    onClick={() => unTakeTask()}
                  >
                    Return to Tasks Pool
                  </Button>
                )}

                <Button
                  className={goToBtnStyle}
                  disabled={goToLabelingDisabled}
                  onClick={() => goToTask()}
                >
                  Go To Labeling
                </Button>
                <Button
                  className={reportABugBtn}
                  onClick={() => setOpenBugWindow(true)}
                >
                  Report a Bug
                </Button>
              </div>
            </div>
          </SplitterLayout>
        </div>

        <BugReportByUser
          openWindow={openBugWindow}
          setOpenWindow={setOpenBugWindow}
        />
        <NotificationContainer />
      </div>
    </>
  ) : null;
};

export default TasksList;
