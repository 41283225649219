import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ASSISTED_WORKS } from '../../SharedLogic';
import { selectConfig } from '../../../../../redux/tasks/tasksSlice';

const EditSelectBatches = ({
  campaignId,
  editBatchesList,
  preSelectedCampaignBatches,
  selectedBatches,
  setSelectedBatches,
  assistedWork,
  aiAsAdditionalTier1,
  alertVisible,
  setAlertVisible,
  alertMessage,
  setAlertMessage,
}) => {
  const [checkboxStyles, setCheckboxStyles] = useState({});
  const config = useSelector(selectConfig);
  const campaignConfig = config?.campaigns?.find(
    (x) => x.campaign_id === campaignId
  );
  const removedBatchesFromCampaign = useMemo(() => {
    return campaignConfig?.params?.removedbatches || [];
  }, [campaignConfig.params.removedbatches]);

  useEffect(() => {
    const initialStyles = {};
    removedBatchesFromCampaign.forEach((batchId) => {
      initialStyles[batchId] = { color: 'red' };
    });
    setCheckboxStyles(initialStyles);
  }, [removedBatchesFromCampaign]);

  const isValidBatchSelection = (batch) => {
    if (assistedWork === ASSISTED_WORKS[1] || aiAsAdditionalTier1) {
      if (!batch.IsDataForAIDetections) {
        return false;
      }
    }

    if (assistedWork === ASSISTED_WORKS[2]) {
      if (!batch.IsDataForGroundTruthLabels) {
        return false;
      }
    }

    return true;
  };

  const handleSelectBatches = (batchId, workStatus) => {
    if (selectedBatches.includes(batchId)) {
      setSelectedBatches(selectedBatches.filter((id) => id !== batchId));
      if (preSelectedCampaignBatches?.includes(batchId)) {
        setAlertVisible((prev) => ({ ...prev, [batchId]: true }));
        setAlertMessage((prev) => ({
          ...prev,
          [batchId]: {
            workStatus: workStatus,
          },
        }));
      }
      setCheckboxStyles((prev) => ({
        ...prev,
        [batchId]: removedBatchesFromCampaign.includes(batchId)
          ? { color: 'red' }
          : undefined,
      }));
      console.log(checkboxStyles);
    } else {
      setSelectedBatches([...selectedBatches, batchId]);
      setAlertVisible((prev) => ({ ...prev, [batchId]: false }));
      setCheckboxStyles((prev) => ({
        ...prev,
        [batchId]: undefined,
      }));
    }
  };

  const LabelWithStatus = ({ label, isExists }) => {
    return (
      <span>
        {label}
        <span style={{ color: isExists ? 'green' : 'red' }}>
          ({isExists ? 'V' : 'X'})
        </span>
      </span>
    );
  };

  const getAlert = (batch) => {
    return (
      <Alert severity="warning">
        <Table
          size="small"
          style={{
            border: '1px solid black',
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell
                rowSpan={2}
                align="center"
                style={{
                  fontWeight: 'bold',
                  border: '1px solid black',
                }}
              >
                Total Cases
              </TableCell>
              {Object.keys(
                alertMessage[batch.BatchId]?.workStatus?.completedTasksByTier ||
                  {}
              ).map((tier) => (
                <TableCell
                  key={tier}
                  colSpan={2}
                  align="center"
                  style={{
                    fontWeight: 'bold',
                    border: '1px solid black',
                  }}
                >
                  Tier {tier}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              {Object.keys(
                alertMessage[batch.BatchId]?.workStatus?.completedTasksByTier ||
                  {}
              ).map((tier) => (
                <React.Fragment key={tier}>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    In Progress
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    Completed
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={{ border: '1px solid black' }}>
                {batch?.CasesCount}
              </TableCell>
              {Object.keys(
                alertMessage[batch.BatchId]?.workStatus?.completedTasksByTier ||
                  {}
              ).map((tier) => (
                <React.Fragment key={tier}>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {
                      alertMessage[batch.BatchId].workStatus
                        ?.inProgressTasksByTier[tier]
                    }
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: '1px solid black' }}
                  >
                    {
                      alertMessage[batch.BatchId].workStatus
                        ?.completedTasksByTier[tier]
                    }
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          </TableBody>
        </Table>
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            border: '1px solid red',
            borderRadius: '5px',
            backgroundColor: '#ffe6e6',
            color: 'red',
          }}
        >
          <strong>Disclaimer:</strong> These are the current progress details
          for this batch. Removing this batch from the campaign will result in
          the permanent loss of all in-progress tasks.
          <strong>
            This action is irreversible and cannot be undone.
          </strong>{' '}
          Please proceed with caution.
        </div>
      </Alert>
    );
  };

  return (
    <>
      <List>
        {editBatchesList?.map((batch) => {
          const labelId = `checkbox-batch-label-${batch.BatchId}`;
          return (
            <ListItem key={batch?.BatchId} disablePadding>
              <ListItem role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    onClick={() =>
                      handleSelectBatches(batch.BatchId, batch.WorkStatus)
                    }
                    disabled={!isValidBatchSelection(batch)}
                    checked={
                      isValidBatchSelection(batch) &&
                      selectedBatches.includes(batch?.BatchId)
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                    sx={{
                      color: checkboxStyles[batch.BatchId],
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={batch?.BatchId}
                  secondary={
                    <>
                      <ListItemText
                        secondary={
                          <LabelWithStatus
                            label={'AI Detections '}
                            isExists={batch?.IsDataForAIDetections}
                          />
                        }
                      />
                      <ListItemText
                        secondary={
                          <LabelWithStatus
                            label={'Ground Truth '}
                            isExists={batch?.IsDataForGroundTruthLabels}
                          />
                        }
                      />
                      {alertVisible[batch.BatchId] && getAlert(batch)}
                    </>
                  }
                />
              </ListItem>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default EditSelectBatches;
